import {
  mdiPlus, mdiDeleteOutline, mdiCheck, mdiPencilOutline, mdiInformationOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'
import cashback from '@/api/cashback/cashback'
import cashbackType from '@/@fake-db/data/cashbackType.json'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'

export default function useCashBackSetting() {
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'id',
      width: 50,
    },
    // {
    //   text: i18n.t('code'),
    //   align: 'start',
    //   value: 'cashback_id',
    //   width: 100,
    // },
    {
      text: i18n.t('type'),
      value: 'cashback_type_id',
      width: 120,
      align: 'center',
    },
    {
      text: i18n.t('nameCashback'),
      value: 'cashback_name',
      width: 200,
    },
    {
      text: i18n.t('netReceipt'),
      value: 'cashback_order',
      align: 'end',
      width: 150,
    },
    {
      text: i18n.t('receiveAmount'),
      value: 'cashback_amount',
      align: 'end',
      width: 150,
    },
    {
      text: i18n.t('amountExpireDate'),
      value: 'cashback_expire',
      align: 'center',
      width: 150,
    },
    {
      text: i18n.t('status'),
      value: 'cashback_status_id',
      align: 'center',
      width: 120,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      sortable: false,
      align: 'center fixed',
      width: 150,
    },
  ])
  const dataTableList = ref([])
  const options = ref({})
  const totalDataTableList = ref(0)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const loading = ref(false)
  const totalPage = ref(0)
  const segmentId = ref(1)
  const searchtext = ref('')
  const statusList = ref(
    [
      {
        'value': '',
        'th': 'สถานะทั้งหมด',
        'en': 'All status',
        'la': 'ສະຖານະພາບທັງໝົດ',
      },
      {
        'value': 1,
        'th': 'ปกติ',
        'en': 'Normal',
        'la': 'ທຳມະດາ',
      },
      {
        'value': '0',
        'th': 'ระงับ',
        'en': 'Suspend',
        'la': 'ໂຈະ',
      }],
  )
  const cashback_type_id = ref('')
  const cashbackTypeList = ref(cashbackType.data)
  const cashback_status_id = ref(1)
  const isAddCashback = ref(false)
  const isEditCashback = ref(false)
  const isUpdateStatus = ref(false)
  const dataEdit = ref({})

  const fetchDataTable = () => {
    loading.value = true
    cashback.cashbackList({
      searchtext: searchtext.value,
      cashback_status_id: cashback_status_id.value,
      cashback_type_id: cashback_type_id.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    })
      .then(res => {
        const { data, count, segment, count_of_page } = res
        totalDataTableList.value = count
        totalPage.value = count_of_page
        dataTableList.value = data
        segmentId.value = segment
        loading.value = false
      })
      .catch(err => {
        console.log('get Checking list error : ', err)
        loading.value = false
      })

  }

  watch([searchtext, cashback_status_id, options, cashback_type_id], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1] || newvalue[2] !== oldvalue[2], newvalue[3] !== oldvalue[3]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    columns,
    dataTableList,
    options,
    searchtext,
    totalDataTableList,
    footer,
    statusList,
    loading,
    cashback_status_id,
    isAddCashback,
    cashback_type_id,
    totalPage,
    isEditCashback,
    dataEdit,
    isUpdateStatus,
    segmentId,
    cashbackTypeList,
    fetchDataTable,
    icons: {
      mdiPlus,
      mdiDeleteOutline, mdiCheck, mdiPencilOutline, mdiInformationOutline,

    },
  }
}
