<template>
  <div>
    <v-dialog v-model='isEditCashback' persistent max-width='500'>
      <v-card>
        <v-card-title>
          {{ $t('editCashbackList') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formEditCashback' @submit.prevent='updateCashback'>
          <v-card-text>
            <v-select
              class='mt-3'
              v-model.trim='cashback_type_id'
              :items='typeListLocal'
              disabled
              :label="$t('type')"
              :item-text='$i18n.locale'
              hide-details='auto'
              outlined
              dense
              item-value='value'
            ></v-select>
            <v-text-field
              v-model='cashback_name'
              dense
              outlined
              :rules='[required]'
              hide-details='auto'
              class='mt-3'
              :label="$t('nameCashback')"
            >
            </v-text-field>
            <v-text-field
              v-model='cashback_order'
              dense
              :rules='[required]'
              outlined
              hide-details='auto'
              class='mt-3'
              type='number'
              :label="$t('netReceipt')"
            >
            </v-text-field>
            <v-text-field
              v-model='cashback_amount'
              dense
              outlined
              :rules='[required]'
              hide-details='auto'
              class='mt-3'
              type='number'
              :label="$t('receiveAmount')"
            >
            </v-text-field>
            <v-text-field
              v-model='cashback_expire'
              dense
              outlined
              :disabled='cashback_type_id == 1'
              :rules='[required]'
              hide-details='auto'
              class='mt-3'
              type='number'
              :label="$t('amountExpireDate')"
            >
            </v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              {{ $t('save') }}
            </v-btn>
            <v-btn color='secondary' outlined @click='$emit(`update:isEditCashback`,false)'>
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import cashback from '@/api/cashback/cashback'
import store from '@/store'

export default {
  model: {
    prop: 'isEditCashback',
    event: 'update:isEditCashback',
  },
  props: {
    isEditCashback: {
      type: Boolean,
      default: false,
    },
    typeList: {
      type: Array,
      default: () => [],
    },
    dataEdit: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {},
  setup(props, { root, emit }) {
    const formEditCashback = ref(null)
    const cashback_type_id = ref('')
    const typeListLocal = ref([])
    const cashback_name = ref('')
    const cashback_order = ref('0')
    const cashback_amount = ref('0')
    const cashback_expire = ref('')
    const isShowDate = ref(false)
    const loading = ref(false)
    const updateCashback = () => {
      const isFormValid = formEditCashback.value.validate()
      if (!isFormValid) return
      loading.value = true
      cashback.updateCashback({
        cashback_type_id: cashback_type_id.value,
        cashback_name: cashback_name.value,
        cashback_order: cashback_order.value,
        cashback_amount: cashback_amount.value,
        cashback_expire: cashback_expire.value,
        cashback_id: props.dataEdit.cashback_id,
      }).then((res) => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onUpdate')
        emit('update:isEditCashback', false)
        loading.value = false
      })
    }

    watch(() => props.isEditCashback, value => {
      if (value) {
        mapData(JSON.parse(JSON.stringify(props.dataEdit)))
      }
    })
    const mapData = (data) => {
      typeListLocal.value = JSON.parse(JSON.stringify(props.typeList)).splice(1, 2)
      cashback_type_id.value = parseInt(data.cashback_type_id)
      cashback_name.value = data.cashback_name
      cashback_order.value = data.cashback_order
      cashback_amount.value = data.cashback_amount
      cashback_expire.value = data.cashback_expire
    }
    return {
      typeListLocal,
      formEditCashback,
      cashback_type_id,
      cashback_name,
      cashback_order,
      cashback_amount,
      cashback_expire,
      isShowDate,
      loading,
      updateCashback,
      required,
    }
  },

}
</script>

<style scoped>

</style>
