<template>
  <div>
    <v-dialog v-model='isAddCashback' persistent max-width='500'>
      <v-card>
        <v-card-title>
          {{ $t('addCashbackList') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formAddCashback' @submit.prevent='createCashback'>
          <v-card-text>
            <v-select
              class='mt-3'
              v-model.trim='cashback_type_id'
              :items='typeListLocal'
              :label="$t('type')"
              :item-text='$i18n.locale'
              hide-details='auto'
              outlined
              dense
              item-value='value'
            ></v-select>
            <v-text-field
              v-model='cashback_name'
              dense
              outlined
              :rules='[required]'
              hide-details='auto'
              class='mt-3'
              :label="$t('nameCashback')"
            >
            </v-text-field>
            <v-text-field
              v-model='cashback_order'
              dense
              :rules='[required]'
              outlined
              hide-details='auto'
              class='mt-3'
              type='number'
              :label="$t('netReceipt')"
            >
            </v-text-field>
            <v-text-field
              v-model='cashback_amount'
              dense
              outlined
              :rules='[required]'
              hide-details='auto'
              class='mt-3'
              type='number'
              :label="$t('receiveAmount')"
            >
            </v-text-field>
            <v-text-field
              v-model='cashback_expire'
              dense
              outlined
              :disabled='cashback_type_id == `1`'
              :rules='[required]'
              hide-details='auto'
              class='mt-3'
              type='number'
              :label="$t('amountExpireDate')"
            >
            </v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              {{ $t('save') }}
            </v-btn>
            <v-btn color='secondary' outlined @click='$emit(`update:isAddCashback`,false)'>
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import cashback from '@/api/cashback/cashback'
import store from '@/store'

export default {
  model: {
    prop: 'isAddCashback',
    event: 'update:isAddCashback',
  },
  props: {
    isAddCashback: {
      type: Boolean,
      default: false,
    },
    typeList: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  setup(props, { root, emit }) {
    const formAddCashback = ref(null)
    const cashback_type_id = ref('')
    const typeListLocal = ref([])
    const cashback_name = ref('')
    const cashback_order = ref('0')
    const cashback_amount = ref('0')
    const cashback_expire = ref('1')
    const isShowDate = ref(false)
    const loading = ref(false)

    const createCashback = () => {
      const isFormValid = formAddCashback.value.validate()
      if (!isFormValid) return
      loading.value = true
      cashback.addCashback({
        cashback_type_id: cashback_type_id.value,
        cashback_name: cashback_name.value,
        cashback_order: cashback_order.value,
        cashback_amount: cashback_amount.value,
        cashback_expire: cashback_expire.value,
      }).then((res) => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onAdd')
        emit('update:isAddCashback', false)
        loading.value = false
      })
    }

    watch(() => props.isAddCashback, value => {
      if (value) {
        clearData()
      }
    })
    const clearData = () => {
      typeListLocal.value = JSON.parse(JSON.stringify(props.typeList)).splice(1, 2)
      cashback_type_id.value = typeListLocal.value[0].value
      cashback_name.value = ''
      cashback_order.value = '0'
      cashback_amount.value = '0'
      cashback_expire.value = '0'
    }
    return {
      typeListLocal,
      formAddCashback,
      cashback_type_id,
      cashback_name,
      cashback_order,
      cashback_amount,
      cashback_expire,
      isShowDate,
      loading,
      createCashback,
      required,
    }
  },

}
</script>

<style scoped>

</style>
