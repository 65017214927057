<template>
  <v-chip
    :color="status == 1?'accent':'info'"
    :class="status==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg accent--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
    small
  >
    {{ status == 1 ? $t('pointFormat') : $t('couponFormat') }}
  </v-chip>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'

export default {
  props: ['status'],
  setup() {
    return {
      icons: {
        mdiCheck,
        mdiClose,
      },
    }
  },
}
</script>
