<template>
  <div>
    <v-dialog v-model='isUpdateStatus' persistent max-width='400'>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon color='error' class='me-2'>{{ mdiAlert }}</v-icon>

          {{ dataUpdate.cashback_status_id == 1 ? $t('confirmSuspendCashback') : $t('cancelSuspendCashback')
          }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='error' @click='updateStatusShop' :loading='loading' :disabled='loading'>
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color='secondary' outlined @click='$emit(`update:isUpdateStatus`,false)'>
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'
import { ref } from '@vue/composition-api'
import cashback from '@/api/cashback/cashback'
import store from '@/store'

export default {
  model: {
    prop: 'isUpdateStatus',
    event: 'update:isUpdateStatus',
  },
  props: {
    isUpdateStatus: {
      type: Boolean,
      default: false,
    },
    dataUpdate: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { root, emit }) {
    const loading = ref(false)
    const updateStatusShop = () => {
      loading.value = true
      cashback.updateCashbackStatus({
        cashback_id: props.dataUpdate.cashback_id,
        cashback_status_id: props.dataUpdate.cashback_status_id == '1' ? '0' : '1',
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'success',
        })
        emit('update:isUpdateStatus', false)
        emit('onUpdate')
      }).catch(err => {
        console.log('err', err)
      }).finally(() => {
        loading.value = false
      })

      loading.value = true


    }

    return { updateStatusShop, mdiAlert, loading }
  },

}
</script>

<style scoped>

</style>
